<template>
  <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:50px">
    <el-row>
      <el-col></el-col>
      <el-col>
        <div>
          <div style="float:right;">
            <h6>Don't have an account?&nbsp;&nbsp;<router-link to="/signup">Sign up</router-link>
            </h6>
          </div>
          <div style="margin:10px">
            <h1>LOGIN</h1>
          </div>
          <div>
            <el-form ref="form" :model="form">
              <el-form-item label="Email" style="width:300px;">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
              <el-form-item label="password" style="width:300px;">
                <el-input type="password" v-model="form.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit" style="width:300px;">Sign In</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-dialog title="List of workspaces" :visible.sync="dialogVisible" :append-to-body="true"
              :lock-scroll="true" custom-class="custom-dialog" width="30%">
              <div>
                <el-select v-model="workSpaceModel" placeholder="select any workspace">
                  <el-option :label="item.company_id.name" v-for="item in workSpaces" :key="item.company_id"
                    :value="item.company_id._id"></el-option>
                </el-select>
              </div>
              <div style="float:right">
                <el-button type="primary" @click="getAuthorizationCode">Next</el-button>
              </div>
            </el-dialog>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.validateUrl();
  },
  computed: {
    ...mapGetters("oauth", ["getLoginOauth", "getTokens"])
  },
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      workSpaces: null,
      dialogVisible: false,
      workSpaceModel: null,
      scopes: ["authorization_code"]
    };
  },
  methods: {
    async validateUrl() {
      let redirectUri = this.$route.query.redirect_uri;
      let client_id = this.$route.query.client_id;
      if (
        (this.$route.query.response_type &&
          this.$route.query.response_type != "code") ||
        !this.$route.query.response_type
      ) {
        this.$notify.error({
          title: "Error",
          message: "url must contain responseType parameter as code"
        });
      }
      if (!redirectUri) {
        this.$notify.error({
          title: "Error",
          message: "url must contain redirect uri but got undefined"
        });
      }
      if (!client_id) {
        this.$notify.error({
          title: "Error",
          message: "url must contain client_id but got undefined"
        });
      }
    },
    async onSubmit() {
      let userDetails = {
        email: this.form.email,
        password: this.form.password
      };
      
      if (userDetails.email.length && userDetails.password.length)
        await this.$store.dispatch("oauth/loginOauth", userDetails);
      if (this.getLoginOauth.error && this.getLoginOauth.error.status == 404) {
        this.$notify.error({
          title: "Error",
          message: this.getLoginOauth.error.message
        });
      } else if (
        this.getLoginOauth.error &&
        this.getLoginOauth.error.status == 403
      ) {
        this.$notify.error({
          title: "Error",
          message: this.getLoginOauth.error.message
        });
      } else {
        this.workSpaces = this.getLoginOauth.userWorkspaceList.workspace;
        this.dialogVisible = true;
      }
    },
    async getAuthorizationCode() {

      let params = {
        // userName: this.form.email,
        // password: this.form.password,
        // user_id: this.getLoginOauth.userWorkspaceList._id,
        // email: this.getLoginOauth.email,
        company_id: this.workSpaceModel,
        // scopes: this.scopes,
        redirect_uri: this.$route.query.redirect_uri,
        state: this.$route.query.state ? this.$route.query.state : "",
        response_type: this.$route.query.response_type,
        client_id: this.$route.query.client_id
      };
      await this.$store.dispatch("oauth/getTokenss", params);
      if (this.getTokens?.status) {
        window.open(
          `${this.getTokens.data.redirectUri}?code=${this.getTokens.data.code}&state=${this.$route.query.state}`,
          "_blank"
        );
      } else {
        if (
          this.getTokens.error.message == "Request failed with status code 400"
        ) {
          this.$notify.error({
            title: "Error",
            message: "redirect_uri does not match client value"
          });
        } else if (
          this.getTokens.error.message == "Request failed with status code 500"
        ) {
          this.$notify.error({
            title: "Error",
            message: this.getTokens.error.message
          });
        }
      }
    }
  }
};
</script>
<style></style>
